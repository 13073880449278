import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled, useTheme } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { toggleDrawer, LOGOUT } from '../../../redux copy/actionTypes';
import PostJobModal from '../Job-post/modal';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/material';
import config from '../../../config/config';
import { getHiringStage } from '../../../utils/employer.api/employer.api.services';
import toast, { Toaster } from 'react-hot-toast';
import { Link,useLocation } from 'react-router-dom';
const drawerWidth = 280;
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  p: 4,
  borderRadius: "20px"
};
const AppBarStyled = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 0,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function AppBar() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const open = useSelector((state) => state.auth.drawerOpen);
  const [openForm, setOpenForm] = useState(false);
  const loginData = useSelector((state) => state.auth.Auth);
  const [isStages, setIsStages] = useState()
  const location = useLocation(); // Get the current location


  const fetchHiringStages = async () => {
    try {
      const response = await getHiringStage(loginData.access_token);
      setIsStages(response.data.company_id)
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    fetchHiringStages();
  }, [loginData.access_token]);
  const handleOpenForm = () => {
    // if (isStages) {
      setOpenForm(true)
    // }
    // else {
    //   toast("Please Create Hiring Stages first")
    //   setTimeout(() => {
    //     window.location.href = `${config.employerUrl}configuration`
    //   }, [500])
    // }
  }
  const handleCloseFrom = () => {
    setOpenForm(false)
  }
  const handleLogout = () => {
    dispatch({ type: LOGOUT });
    setTimeout(() => {
      window.location.reload(true);
      window.location.href = `${config.baseUrl}login`
    }, 1000);
  };
  useEffect(() => {
    if (location.pathname === '/employer/post-job') {
      dispatch(toggleDrawer(false)); // Explicitly close the drawer
    }
  }, [location.pathname, dispatch]);
  const handleDrawerOpen = () => {
    if (location.pathname !== '/employer/post-job') { // Check if the path is not '/employer/post-job'
      dispatch(toggleDrawer(true));
    }
  };
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => {
    setOpenModal(true)
  }
  const handleClose = () => {
    setOpenModal(false)
  }
  return (
    <AppBarStyled position="fixed" open={open} className='appbar'>
      <Toaster/>
      <Toolbar>
      {location.pathname !== '/employer/post-job' && (
          <IconButton
            color="#000"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Link to={`${config.employerUrl}dashboard`}>
        <Typography variant="h6" noWrap component="div">
          <h2 className="logo text-2xl font-semibold font-family">
            <span className="color-text font-family">Company</span>
          </h2>
        </Typography>
        </Link>
        <div className=' ms-auto'>
          <Link to={`${config.employerUrl}post-job`}>
          <button type='button' className='theme-btn mr-3'>+ Post job</button>
          </Link>
          <button className='theme-btn ' onClick={handleOpen}>
            Logout
          </button>
        </div>
        {/* <PostJobModal openForm={openForm} handleCloseFrom={handleCloseFrom} setOpenForm={setOpenForm} /> */}
      </Toolbar>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" className='text-center'>
            Are you sure want to logout ?
          </Typography>

          <div className='justify-center items-center mt-5 flex'>

            <button onClick={handleClose} color="primary" sx={{ mt: 2 }} className='py-2.5 px-5 me-2 mb-2  text-sm font-medium text-black rounded-lg bg-gray-300'>
              No
            </button>
            <button onClick={handleLogout} sx={{ mt: 2 }} className=' py-2.5 px-5 me-2 mb-2 text-white text-sm font-medium text-wh theme-btn   rounded-lg'>
              Yes
            </button>
          </div>
        </Box>
      </Modal>
    </AppBarStyled>
  );
}
