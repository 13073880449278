import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import DrawerHeader from '../dashboard/DrawerHeader';
import config from '../../../config/config';
import Modal from '@mui/material/Modal';
import PostJobForm from '../Job-post/postFrom';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { getJobs } from '../../../utils/employer.api/employer.api.services';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 4,
    borderRadius: "10px"
};
const JobStatus = () => {
    const loginData = useSelector((state) => state.auth.Auth);
    const [jobData, setJobData] = useState({
        openedJobs: [],
        onHoldJobs: [],
        closedJobs: []
    });

    const fetchJobs = async () => {
        try {
            const response = await getJobs(loginData.access_token)

            if (response.status === 200) {
                setJobData({
                    openedJobs: response.data['Opened Jobs'].jobs,
                    onHoldJobs: response.data['On Hold Jobs'].jobs,
                    closedJobs: response.data['Closed Jobs'].jobs
                });
            }
        } catch (error) {
            console.error("Error fetching jobs:", error);
        }
    };
    useEffect(() => {
        fetchJobs();
    }, [loginData.access_token]);
    
    const [selectedJob, setSelectedJob] = React.useState(null);
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const handleViewJob = (job) => {
        setSelectedJob(job); // Set the job data
        setIsModalOpen(true); // Open the modal
    };

    const handleCloseModal = () => {
        setIsModalOpen(false); // Close the modal
        setSelectedJob(null);  // Clear the selected job data
    };
    const updateJobStatus = async (jobId, status) => {
        try {
            const response = await axios.patch(`${config.apiUrl}employer/jobs/${jobId}/status`, null, {
                params: { job_status: status },
                headers: {
                    'Authorization': `Bearer ${loginData.access_token}`
                }
            });
            if (response.status === 200) {
                console.log('Job status updated successfully');
            }
        } catch (error) {
            console.error('Error updating job status:', error);
        }
    };

    // Drag and Drop
    const JobCard = ({ job, status }) => {
        const [{ isDragging }, dragRef] = useDrag({
            type: 'jobCard',
            item: { job, status },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            })
        });

        return (
            <div
                ref={dragRef}
                style={{ opacity: isDragging ? 0.5 : 1 }}
                className="status-card mt-7"
            >
                <div className={`job-card card1 ${status === 'Opened' ? 'blue' : status === 'On Hold' ? 'yellow' : 'red'}`}>
                    <button className="view-black" onClick={() => handleViewJob(job)}>
                        <Link to="#">View</Link>
                    </button>

                    <div className="p-2  relative overflow-hidden">
                        <div className='view-user'>
                            <Link to={`${config.employerUrl}total-applicants/${job.id}`}>
                                <img src='images/icons/multi-users.svg' />
                                <h4>View<br /> Applicants</h4>
                            </Link>
                        </div>
                        <div className='ml-12 mt-4 pr-16'>
                            <h3>{job.title}</h3>
                            <span>{job.salary_min} - {job.salary_max} CTC, {job.location}</span>
                        </div>
                        <div className="mt-2 type-btns flex">
                            <button><img src="images/icons/location.svg" />{job.job_mode}</button>
                            <button className="mx-2"><img src="images/icons/cap.svg" />{job.experience}</button>
                            <button><img src="images/icons/clock.svg" />{job.job_type}</button>
                        </div>
                    </div>
                    <hr />
                    <div className="flex justify-between p-4" style={{background:"#fff"}}>
                        <span>{job.posted_date}</span>
                        <span>Notice Period: {job.notice_period}</span>
                    </div>
                </div>
            </div>
        );
    };

    const JobSection = ({ title, status, jobs, onDropJob }) => {
        const [{ isOver }, dropRef] = useDrop({
            accept: 'jobCard',
            drop: (item) => {
                if (item.status !== status) {
                    onDropJob(item.job, status);
                }
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
            })
        });

        return (
            <div ref={dropRef} className={`job-section ${status.toLowerCase()} mb-3`} style={{ backgroundColor: isOver ? '#e0e0e0' : 'inherit' }}>
                <div className="section-name mb-3">
                    <div className="dot"></div>
                    <span>{title} ({jobs.length})</span>
                </div>
                {jobs.length > 0 ? (
                    jobs.map((job, index) => (
                        <JobCard key={index} job={job} status={status} />
                    ))
                ) : (
                    <div>No {title.toLowerCase()} jobs</div>
                )}
            </div>
        );
    };

    const handleDropJob = (job, newStatus) => {
        // Determine the old status of the job
        let oldStatus;
        if (jobData.openedJobs.includes(job)) {
            oldStatus = 'Opened';
        } else if (jobData.onHoldJobs.includes(job)) {
            oldStatus = 'On Hold';
        } else if (jobData.closedJobs.includes(job)) {
            oldStatus = 'Closed';
        }
    
        // Update the job status locally in the UI
        setJobData((prevData) => {
            const updatedOpenedJobs = newStatus === 'Opened' 
                ? [...prevData.openedJobs, job] 
                : prevData.openedJobs.filter(j => j.id !== job.id);
    
            const updatedOnHoldJobs = newStatus === 'On Hold' 
                ? [...prevData.onHoldJobs, job] 
                : prevData.onHoldJobs.filter(j => j.id !== job.id);
    
            const updatedClosedJobs = newStatus === 'Closed' 
                ? [...prevData.closedJobs, job] 
                : prevData.closedJobs.filter(j => j.id !== job.id);
    
            return {
                openedJobs: updatedOpenedJobs,
                onHoldJobs: updatedOnHoldJobs,
                closedJobs: updatedClosedJobs,
            };
        });
    
        // Call API to update the job status on the backend
        updateJobStatus(job.id, newStatus);
    };
    

    return (
        <DndProvider backend={HTML5Backend}>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }} className="mainContent">
                <DrawerHeader />
                <div>
                    <h2 className="menu-heading">Job Listing</h2>
                </div>
                <div className="p-4">
                    <div className="grid gap-x-6 gap-y-3 mb-6 xl:grid-cols-3 lg:grid-cols-2 sm:grid-cols-1">
                        <JobSection title="Opened Jobs" status="Opened" jobs={jobData.openedJobs} onDropJob={handleDropJob} />
                        <JobSection title="On Hold Jobs" status="On Hold" jobs={jobData.onHoldJobs} onDropJob={handleDropJob} />
                        <JobSection title="Closed Jobs" status="Closed" jobs={jobData.closedJobs} onDropJob={handleDropJob} />
                    </div>
                </div>

                {isModalOpen && (
                    <Modal open={isModalOpen} onClose={handleCloseModal}>
                        <Box sx={style}>
                            <PostJobForm handleCloseFrom={handleCloseModal} initialData={selectedJob} />
                        </Box>
                    </Modal>
                )}
            </Box>
        </DndProvider>
    );
};

export default JobStatus;
