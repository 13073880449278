import React, { useEffect, useState } from "react";
import { getSkills } from "../../../utils/candidate.api/candidate.api.services";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const JobDescriptionForm = ({ formData, setFormData, initialData,errors,setErrors }) => {
    const [skills, setSkills] = useState([]);
    const [selectedSkills, setSelectedSkills] = useState([]);

    const fetchSkills = async () => {
        try {
            let response = await getSkills();
            setSkills(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "skill_set") {
            const selectedSkills = value;
            setSelectedSkills(selectedSkills);
            setFormData((prevData) => ({
                ...prevData,
                skill_set: selectedSkills.map(skill => ({ name: skill }))
            }));
        } else {
            setFormData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors.jobDetailsErrors };
            if (value) delete updatedErrors[name]; // Remove specific error if field is filled
            return {
              ...prevErrors,
              jobDetailsErrors: updatedErrors,
            };
          });
    };

    useEffect(() => {
        // Pre-populate the form with initialData if it exists
        if (initialData) {
            setFormData({
                job_description: initialData.job_description || '',
                skill_set: initialData.skill_set || [],
            });

            // Pre-select the skills based on initialData
            if (initialData.skill_set) {
                const skillNames = initialData.skill_set.map(skill => skill.name);
                setSelectedSkills(skillNames);
            }
        }
    }, [initialData, setFormData]);

    useEffect(() => {
        fetchSkills();
    }, []);

    return (
        <>
            <div className="form-theme mt-8">
                <div className="grid gap-x-6 gap-y-3 mb-6 md:grid-cols-1">
                    <div>
                        <label htmlFor="job_description" className="block mb-0">Job Description<span className="text-red-500">*</span></label>
                        <textarea
                            id="job_description"
                            name="job_description"
                            rows="4"
                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
                            placeholder="Write your thoughts here..."
                            value={formData.job_description || ''}
                            onChange={handleInputChange}
                        />
                        {errors.job_description && <p className="text-xs text-red-500">{errors.job_description}</p>}
                    </div>
                    <div>
                        <label htmlFor="skills" className="block mb-2">Skills<span className="text-red-500">*</span></label>
                        <Select
                            multiple
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            className="w-1/2 border border-gray-300 mb-2"
                            name="skill_set"
                            value={selectedSkills}  // use the array of skill names (strings)
                            onChange={handleInputChange}
                            input={<OutlinedInput id="select-multiple-chip" />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((skillName) => (
                                        <Chip key={skillName} label={skillName} />
                                    ))}
                                </Box>
                            )}
                            MenuProps={MenuProps}
                        >
                            {skills.map((skill) => (
                                <MenuItem key={skill} value={skill}>
                                    {skill}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.skill_set && <p className="text-xs text-red-500">{errors.skill_set}</p>}
                    </div>
                </div>
            </div>
        </>
    );
};

export default JobDescriptionForm;
