import React, { useState, useEffect } from "react";
import config from "../../../config/config";
import * as Yup from 'yup';
import axios from "axios";
import { Toaster, toast } from 'react-hot-toast';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Auth } from "../../../redux copy/actionTypes";
import CircularProgress from '@mui/material/CircularProgress';


const WithOTP = ({ switchToPassword }) => {
  const [company_email, setCompany_email] = useState("");
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false); // loading state
  const [timer, setTimer] = useState(30); // 2-minute timer (30 seconds)
  const [canResendOtp, setCanResendOtp] = useState(false); // control OTP resend button
  const [errors, setErrors] = useState({})
  const dispatch = useDispatch();
  const[otpErrors,setOtpErrors]= useState("")


  // Timer effect
  useEffect(() => {
    let interval;
    if (showOtpInput && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setCanResendOtp(true); // Allow OTP resend after timer hits 0
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [showOtpInput, timer]);

  // Reset timer function
  const resetTimer = () => {
    setTimer(30); // Reset to 2 minutes
    setCanResendOtp(false);
  };

  const validationSchema = Yup.object().shape({
    company_email: Yup.string()
      .email('Invalid email format') // Validates email format
      .required('Email is required'), // Checks if the email is not empty
  });

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate({ company_email }, { abortEarly: false });
      setLoading(true)
      const response = await axios.post(`${config.apiUrl}employer/login/otp`, { company_email });
      if (response.status === 200) {
        toast.success("OTP has been sent");
        setShowOtpInput(true);
        resetTimer(); // Start/restart the timer
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      handleErrors(error);
    }
    finally {
      setLoading(false)
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if(otp===""){
        setOtpErrors("OTP required")
      }
      const response = await axios.post(`${config.apiUrl}employer/login/otp/verify`, { otp, company_email });
      if (response.status === 200) {
        dispatch({
          type: Auth,
          payload: response.data,
        });
        // Redirect or handle login logic
      } else {
        toast.error("Failed to verify OTP");
      }
    } catch (error) {
      if(otp !== "" ){
        setOtpErrors(error.response.data.detail)
      }
      else{
       console.log(error)
      }
    }finally {
      setLoading(false);
    }
  };

  const handleResendOtp = async () => {
    try {
      setLoading(true)
      const response = await axios.post(`${config.apiUrl}employer/login/otp/resend`, { company_email });
      if (response.status === 200) {
        toast.success("OTP resent successfully");
        resetTimer(); // Restart the timer
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      handleErrors(error);
    }
    finally {
      setLoading(false)
    }
  };


  const handleErrors = (error) => {
    if (error.response) {
      const apiErrorDetail = error.response.data?.detail;

      if (typeof apiErrorDetail === 'string') {
        // Handle API errors for both fields
        if (apiErrorDetail.includes("OTP")) {
          setErrors({ otp: apiErrorDetail });
        } else {
          setErrors({ company_email: apiErrorDetail });
        }
      } else if (Array.isArray(apiErrorDetail)) {
        const apiErrors = {};
        apiErrorDetail.forEach(err => {
          apiErrors[err.loc[1]] = err.msg;
        });
        setErrors(apiErrors);
      }
    } else if (error instanceof Yup.ValidationError) {
      const validationErrors = {};
      error.inner.forEach(err => {
        validationErrors[err.path] = err.message;
      });
      setErrors(validationErrors);
    } else {
      console.error(error);
    }
  };


  return (
    <>
      <Toaster />
      <div className="">
        {!showOtpInput ? (
          <form className="space-y-4" onSubmit={handleLogin}>
            <div>
              <label className="block">Email</label>
              <input
                type="text"
                id="email"
                name="company_email"
                value={company_email}
                onChange={(e) => {
                  setCompany_email(e.target.value); // Update the email value
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    company_email: undefined, // Remove error for company_email field
                  }));
                }}
                className="mt-1 block w-full p-3 border shadow-sm placeholder-gray-400 focus:outline-none sm"
                placeholder="Enter your email"
              />
              {errors.company_email && <span className="text-red-500 text-xs">{errors.company_email}</span>}
            </div>
            <div>
              <Link to="#" onClick={switchToPassword} className="float-right mb-3 text-blue-500" style={{ fontFamily: "poppins medium" }}>Login with Password</Link>
            </div>
            <div>
              <button type="submit" className="theme-btn rounded-md mr-2 text-white w-full">Send OTP
                {loading && (
                  <CircularProgress
                    size={20}
                    disableShrink
                    style={{ color: "#fff", margin: "0 10px" }}
                  />
                )}

              </button>
            </div>
          </form>
        ) : (
          <form className="space-y-4 mt-4 " onSubmit={handleOtpSubmit}>
            <div>
              <label className="block">Enter OTP</label>
              <input
                type="text"
                name="otp"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className="mt-1 block w-full p-3 border shadow-sm placeholder-gray-400 focus:outline-none sm"
                placeholder="Enter the OTP"
              />
                {otpErrors && <span className="text-red-500 text-xs">{otpErrors}</span>}
            </div>
            <div>
              <button type="submit" className="theme-btn rounded-md mr-2 text-white">
                Continue  {loading && (
                  <CircularProgress
                    size={20}
                    disableShrink
                    style={{ color: "#fff", margin: "0 10px" }}
                  />
                )}
              </button>
            </div>
            {timer > 0 ? (
              <p className="text-sm">Resend OTP in {Math.floor(timer / 60)}:{(timer % 60).toString().padStart(2, '0')}</p>
            ) : (
              <p className="text-sm cursor-pointer">
                Didn't get the OTP?<span className="text-blue-500 cursor-pointer" onClick={handleResendOtp}>
                  Resend OTP
                </span>
              </p>
            )}
          </form>
        )}
      </div>
    </>
  );
};

export default WithOTP;
