import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import toast, { Toaster } from 'react-hot-toast';
import { scheduleInterviewAPI } from '../../utils/employer.api/employer.api.services';
import zIndex from '@mui/material/styles/zIndex';

const style = {
  position: 'absolute',
  top: '20px',
  left: '50%',
  transform: 'translate(-50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  borderRadius: "16px",
  zIndex:1
};

export default function InterviewSchedule({ openModal, closeModal, jobId, applicantId, token,applicantData,applicantStages }) {
  const [currentStage, setCurrentStage] = useState(applicantData.stage);
  const [interviewStage, setInterviewStage] = useState('');
  const [interviewerName, setInterviewerName] = useState('');
  const [interviewDate, setInterviewDate] = useState('');
  const [interviewTime, setInterviewTime] = useState('');
  const [interviewType, setInterviewType] = useState('');
  const [joiningLink, setJoiningLink] = useState('');
  const [location, setLocation] = useState('');
  const [phone, setPhone] = useState('');


  let payload = {
    current_stage: currentStage,
    applicant_id: applicantId,
    interview_stage: interviewStage,
    interviewer_name: interviewerName,
    interview_date: interviewDate,
    interview_time: interviewTime,
    interview_type: interviewType,
  };
  const resetFields = () => {
    setCurrentStage('');
    setInterviewStage('');
    setInterviewerName('');
    setInterviewDate('');
    setInterviewTime('');
    setInterviewType('');
    setJoiningLink('');
    setLocation('');
    setPhone('');
  };

  const handleSubmit = async () => {
    if (!interviewStage || !interviewerName || !interviewDate || !interviewTime || !interviewType) {
      toast.error("Please fill in all required fields");
      return;
    }

    try {
      const response = await scheduleInterviewAPI(jobId, payload, token);
      if (response.status === 201) {
        toast.success("Interview scheduled successfully");
        closeModal()
        setTimeout(() => {
          window.location.reload(true);
        }, 500);
        resetFields();
      }
    } catch (error) {
      toast.error("somthing went wrong")
      console.log(error)

    }
  };
  if (interviewType === 'Video call') {
    payload.joining_link = joiningLink;
    // Remove phone and location
    delete payload.phone_number;
    delete payload.location;
  } else if (interviewType === 'Phone') {
    payload.phone_number = phone;
    // Remove joining link and location
    delete payload.joining_link;
    delete payload.location;
  } else if (interviewType === 'In-office') {
    payload.location = location;
    // Remove phone and joining link
    delete payload.phone_number;
    delete payload.joining_link;
  }
const handleCloseModal =() =>{
  resetFields()
  closeModal();
}
// console.log("app data",applicantStages)
  return (
    <div>
      <Toaster/>
      <Modal
        open={openModal}
        top
        // onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          
          <div className='px-10 pt-5' style={{ borderRadius: "16px 16px 0 0" }}>
            <h2 className='heading2'>Schedule Interview</h2>
          </div>
          <div className='modal-form px-10 py-5'>
            <form className='form-theme'>
              {/* Input fields */}
              <div className='grid grid-cols-2'>
                <div className='relative mb-4 mr-3'>
                  <label>Current Stage</label>
                  <input type='input' className='w-full border border-gray-300' value={applicantData.stage} readOnly/>
                </div>
                {/* <div className='relative mb-4 '>
                  <label>Interview Stage</label>
                  <input
                    type='text'
                    placeholder='Stage name'
                    className='w-full border border-gray-300'
                    value={interviewStage}
                    onChange={(e) => setInterviewStage(e.target.value)}
                  />
                </div> */}
                <div className='relative mb-4 mr-3'>
                  <label>Interview Stage</label>
                  <select
                    className="w-full border border-gray-300"
                    value={interviewStage}
                    onChange={(e) => setInterviewStage(e.target.value)}
                    placeholder="Select stage"
                  >
                     <option value="" disabled hidden>Interview Stage</option>
                    {applicantStages.map((stage) =>(
                    <option value={stage.name}>{stage.name}</option>
                    ))}
                    {/* <option value="" disabled hidden>Interview Stage</option>
                    <option value="Technical Round 1">Technical Round 1</option>
                    <option value="HR Round">HR Round</option>
                    <option value="Hired">Hired</option> */}
                  </select>
                </div>
                <div className='relative mb-4 mr-3'>
                  <label>Interviewer</label>
                  <select
                    className="w-full border border-gray-300"
                    value={interviewerName}
                    onChange={(e) => setInterviewerName(e.target.value)}
                  >
                    <option value="" disabled hidden>Select Interviewer</option>
                    <option value="Shreyansh Singh">Shreyansh Singh</option>
                  </select>
                </div>
                <div className='relative mb-4 mr-3'>
                  <label>Interview Date</label>
                  <input
                    type="date"
                    // type="input"
                    className="w-full border border-gray-300"
                    value={interviewDate}
                    onChange={(e) => setInterviewDate(e.target.value)}
                  />
                </div>
                <div className='relative mb-4'>
                  <label>Interview Time</label>
                  <select
                    className="w-full border border-gray-300"
                    value={interviewTime}
                    onChange={(e) => setInterviewTime(e.target.value)}
                  >
                    <option value="" disabled hidden>Pick a slot</option>
                    <option value="11:00 AM - 11:30 AM">11:00 AM - 11:30 AM</option>
                    <option value="12:00 PM - 12:30 PM">12:00 PM - 12:30 PM</option>
                    <option value="4:00 PM - 4:30 PM">4:00 PM - 4:30 PM</option>
                    <option value="5:00 PM - 5:30">5:00 PM - 5:30 PM</option>
                    <option value="6:00 PM - 6:30 PM">6:00 PM - 6:30 PM</option>
                  </select>
                </div>
              </div>
              <div className='my-4'>
                <button type='button' className='py-2 px-3 mr-3 rounded-full bg-[#C4C4C4] text-sm text-white inline-flex items-center'
                  onClick={() => setInterviewType('Video call')}>
                  <img src='images/icons/video.svg' alt='video' className='mr-2' />Video call
                </button>
                <button type='button' className='py-2 px-3 mr-3 rounded-full bg-[#C4C4C4] text-sm text-white inline-flex items-center'
                  onClick={() => setInterviewType('Phone')}>
                  <img src='images/icons/phone-light.svg' alt='phone' className='mr-2' />Phone
                </button>
                <button type='button' className='py-2 px-3 mr-3 rounded-full bg-[#C4C4C4] text-sm text-white inline-flex items-center'
                  onClick={() => setInterviewType('In-office')}>
                  <img src='images/icons/company-light.svg' alt='company' className='mr-2' />In-office
                </button>
              </div>
              {/* Conditionally render input fields based on interview type */}
              {interviewType === 'Video call' && (
                <div className='relative mb-4'>
                  <label>Joining Link</label>
                  <input
                    type='  '
                    placeholder='https://meet.google.com/otd-dckc-hkb'
                    className='w-full border border-gray-300'
                    value={joiningLink}
                    onChange={(e) => setJoiningLink(e.target.value)}
                  />
                </div>
              )}

              {interviewType === 'Phone' && (
                <div className='relative mb-4'>
                  <label>Phone Number</label>
                  <input
                    type='text'
                    placeholder='+1234567890'
                    className='w-full border border-gray-300'
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
              )}

              {interviewType === 'In-office' && (
                <div className='relative mb-4'>
                  <label>Location</label>
                  <input
                    type='text'
                    placeholder='Office Location'
                    className='w-full border border-gray-300'
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                  />
                </div>
              )}

              <div className='flex justify-end'>
                <button type="button" className="theme-btn bordered-btn py-2 mr-3" onClick={handleCloseModal}>
                  <span className="color-text">Cancel</span>
                </button>
                <button type="button" className="theme-btn bordered-btn py-2" onClick={handleSubmit}>
                  <span className="color-text">Save</span>
                </button>
              </div>
            </form>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
