import axiosClient from "../../config/axios.Config";

export const getIndustry = () => {
  return axiosClient("employer/industries", "", "GET");
};
export const getProfileInfo = (token) => {
    return axiosClient("employer/company-info", "", "GET", {
      Authorization: `Bearer ${token}`,
    });
  };
  export const getHiringStage = (token) => {
    return axiosClient("employer/hiring-stages", "", "GET", {
      Authorization: `Bearer ${token}`,
    });
  };
  export const getJobs = (token) => {
    return axiosClient("employer/jobs", "", "GET", {
      Authorization: `Bearer ${token}`,
    });
  };
  export const getCompanyInfo = (token) => {
    return axiosClient("employer/company-info", "", "GET", {
      Authorization: `Bearer ${token}`,
    });
  };
  export const scheduleInterviewAPI = (jobId, payload, token) => {
    return axiosClient(`employer/${jobId}/schedule-interview`, payload, "POST", {
      Authorization: `Bearer ${token}`,
    });
  };
  