import React, { useState } from "react";
import config from "../../../config/config";
import * as Yup from 'yup';
import axios from "axios";
import { Toaster, resolveValue, toast } from 'react-hot-toast';
import { Link } from "react-router-dom";
import { Auth } from "../../../redux copy/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CircularProgress from '@mui/material/CircularProgress';



const WithPassword = ({ switchToOtp }) => {
  const [form, setForm] = useState({ "password": "", "company_email": "" });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false); // Add loading state


  const dispatch = useDispatch()

  const validationSchema = Yup.object({
    password: Yup.string()
      .required('Password is required'),
    company_email: Yup.string().required('Email is required'),
  });

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };
  // const loginData = useSelector((state) => state.auth.Auth);
  const [errors, setErrors] = useState({});
  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate(form, { abortEarly: false });
      setLoading(true)
      const config1 = {
        method: 'post',
        url: `${config.apiUrl}employer/login`,
        data: form
      };

      const response = await axios(config1);

      if (response.status === 200) {
        console.log("Success:", response.data);
        dispatch({
          type: Auth,
          payload: response.data
        })
      } else {
        toast.error("Something went wrong");
      }
    }  catch (error) {
      if (error.response) {
        // Handling server-side errors
        console.error("API Error:", error.response.data);
  
        // Check if the error response is in the expected format
        if (Array.isArray(error.response.data.detail)) {
          const apiErrors = {};
          error.response.data.detail.forEach((err) => {
            // Mapping error messages to the field name
            if (err.loc && err.loc.length === 2) {
              const field = err.loc[1];
              apiErrors[field] = err.msg; // Example: "Invalid email format"
            }
          });
          setErrors(apiErrors);
        } else {
          toast.error(error.response.data.detail || "API error occurred");
        }
      } else if (error instanceof Yup.ValidationError) {
        // Handling validation errors from Yup
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
      } else {
        console.error(error);
        toast.error("Unexpected error occurred");
      }
    } 
    finally {
      setLoading(false)
    }
  };



  const inputHandler = (e) => {
    const { name, value } = e.target;
    setForm((old) => ({ ...old, [name]: value }));
    setErrors((oldErrors) => ({
      ...oldErrors,
      [name]: undefined, // Clear the error for the current field
    }));
  };

  return (
    <>
      <Toaster />
      <div className="">
        <form className="space-y-4 " onSubmit={handleLogin}>
          <div>
            <label className="block">Company Email</label>
            <input
              type="text"
              id="email"
              name="company_email"
              value={form.company_email}
              onChange={inputHandler}
              className={`mt-1 block w-full p-3 border shadow-sm placeholder-gray-400 focus:outline-none sm 
                ${errors.company_email ? 'border-red-500' : 'border-gray-300'}`}
              placeholder="Enter company Email"
            />
            {errors.company_email && <span className="text-red-500 text-xs">{errors.company_email}</span>}
          </div>
          <div>
            <label className="block">Password</label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={form.password}
                onChange={inputHandler}
                className={`mt-1 block w-full p-3 border shadow-sm placeholder-gray-400 focus:outline-none sm 
                ${errors.password ? 'border-red-500' : 'border-gray-300'}`}
                placeholder="Enter your password"
              />
              {errors.password && <span className="text-red-500 text-xs">{errors.password}</span>}
              <span
                onClick={togglePasswordVisibility}
                className="absolute right-3 top-1 cursor-pointer"
              >
                {showPassword ? <VisibilityOffIcon fontSize="14px"/> : <VisibilityIcon fontSize="14px"/>}
              </span>
            </div>
          </div>
            <div>
              <Link to="#" onClick={switchToOtp} className="float-right mb-3 text-blue-500" style={{fontFamily:"poppins medium"}}>
              Login with OTP</Link>
            </div>
          <div>
            <button type="submit" className="theme-btn mr-2 text-white w-full rounded-md">Sign In  {loading == true ?
              <CircularProgress
                size={20}
                disableShrink
                style={{ color: "#fff", margin: "0 10px" }} />
              : null}</button>
          </div>
        </form>
      </div>
    </>
  );
};

export default WithPassword;
