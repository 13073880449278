import React, { useState, useEffect } from 'react';
import CandidateLogin from './candidate/CandidateLogin';
import EmployerLogin from './employer/EmployerLogin';
import LeftImage from '../design/LeftImage';
import { useSelector } from 'react-redux';
import { Navigate, useLocation, Link } from 'react-router-dom';
import config from '../../config/config';
import { getCompanyInfo } from '../../utils/employer.api/employer.api.services';
import { getCandidateProfile } from '../../utils/candidate.api/candidate.api.services'; // Import the candidate profile API

function Login() {
  const location = useLocation();
  const initialType = location.state?.userType || 'candidate';  // Get the state passed from signup page
  const [showComponent, setshowComponent] = useState(initialType);
  const [activeBtn, setActiveBtn] = useState(initialType);
  const [redirectTo, setRedirectTo] = useState(null); // State to manage redirection

  const handleCandidate = () => {
    setshowComponent('candidate');
    setActiveBtn('candidate');
  };

  const handleEmployer = () => {
    setshowComponent('employer');
    setActiveBtn('employer');
  };

  const loginData = useSelector((state) => state.auth.Auth);
  const userType = useSelector((state) => state.auth.Auth);

  // In the Login component's useEffect
  useEffect(() => {
    const checkProfile = async () => {
      if (loginData.access_token) {
        if (userType.role === 'employer') {
          try {
            const profileData = await getCompanyInfo(loginData.access_token); // Call the employer fetchProfile API
            console.log(profileData);
            // Check for employer profile existence
            if (profileData.data == null) {
              setRedirectTo(`${config.employerUrl}setting`);
            } else {
              setRedirectTo(`${config.employerUrl}dashboard`);
            }
          } catch (error) {
            console.error('Error fetching employer profile:', error);
          }
        } else if (userType.role === 'candidate') {
          try {
            const candidateProfileData = await getCandidateProfile(loginData.access_token); // Call the candidate fetchProfile API
            console.log(candidateProfileData);
            // Check for candidate profile existence
            if (candidateProfileData.data === null) {
              setRedirectTo(`${config.candidateUrl}setting`);
            } else {
              setRedirectTo(`${config.candidateUrl}dashboard`);
            }
          } catch (error) {
            console.error('Error fetching candidate profile:', error);
          }
        }
      }
    };
    checkProfile();
  }, [loginData.access_token, userType.role]);

  if (loginData.access_token) {
    if (userType.role === 'candidate' && redirectTo) {
      return <Navigate to={redirectTo} />;
    } else if (userType.role === 'employer' && redirectTo) {
      return <Navigate to={redirectTo} />;
    }
  }

  return (
    <div className='bg-[#F3F9FC] forms '>
      <div className="mx-auto sm:max-w-full sm:max-w-[1200px] signup">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-7">
            <LeftImage />
          </div>
          <div className="bg-white p-md-4 pt-28 pr-20 pl-10 col-span-5">
            <div className='mx-4 p-md-5 '>
              <div className='flex justify-center items-center'>
                <button type="button" className={`me-2 bordered-btn theme-btn ${activeBtn === 'candidate' ? 'active' : ''}`} onClick={handleCandidate}>Job Seeker</button>
                <button type="button" className={`me-2 bordered-btn theme-btn ${activeBtn === 'employer' ? 'active' : ''}`} onClick={handleEmployer}>Company</button>
              </div>
              <div className='py-3'>
                {/* <h1>Welcome Back</h1> */}
              </div>
            </div>
            {showComponent === 'employer' && <EmployerLogin />}
            {showComponent === 'candidate' && <CandidateLogin />}
            <div className="footer-content px-4">
              <p className="py-3">Already have an account?  <Link to="/signup" state={{ userType: activeBtn }}>Sign Up</Link></p>
                {/* <p>By clicking ‘Create Account’, you acknowledge that you have read and accept the <Link to={`${config.baseUrl}terms-and-conditions`}>Terms of Service </Link>
                and <Link to={`${config.baseUrl}privacy-policy`}> Privacy Policy.</Link></p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
