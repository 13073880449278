import axiosClient from "../../config/axios.Config";

export const UploadProfileAPI = (payload, token) => {
  return axiosClient("candidate/upload_photo", payload, "POST", {
    Authorization: token,
  });
};
export const getJobRecommendations = (token) => {
  return axiosClient("candidate/recommendations", "", "Get", {
    Authorization: `Bearer ${token}`,
  });
};
export const getLocation = () => {
  return axiosClient("candidate/indian-states", "", "GET");
};
export const getSkills = () => {
  return axiosClient("candidate/skills", "", "GET");
};
export const getCountryColde = () => {
  return axiosClient("candidate/country-codes", "", "GET");
};
export const getFieldStudy = () => {
  return axiosClient("candidate/fields-of-study", "", "GET");
};
export const getCandidateProfile = (token) => {
  return axiosClient(`candidate/profile`, "", "Get", {
    Authorization: `Bearer ${token}`,
  });
};
