import React, { useState ,useEffect} from "react";
import config from "../../config/config";
import * as Yup from 'yup';
import axios from "axios";
import { Toaster, resolveValue, toast } from 'react-hot-toast';
import { Link } from "react-router-dom";
import SignupFooter from "./signupFooter";
import { useDispatch } from "react-redux";
import { Auth } from "../../redux copy/actionTypes";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CircularProgress from '@mui/material/CircularProgress';


const EmployerSignup = () => {
  const [form, setForm] = useState({
    company_email: "",
    name: "",
    password: "",
    confirm_password: "",
    phone_number: "",
    company_name: ""
  });
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch()
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false)
  const [timeLeft, setTimeLeft] = useState(30); // Set 2 minutes (30 seconds)
  const [canResendOtp, setCanResendOtp] = useState(false);
  const[otpErrors,setOtpErrors]= useState("")



  useEffect(() => {
    let timer;
    if (showOtpInput && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prev) => prev - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setCanResendOtp(true);
    }

    return () => clearInterval(timer); // Cleanup timer on unmount
  }, [showOtpInput, timeLeft]);
  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };
  const toggleConfirmPassword = () => {
    setshowConfirmPassword((prevState) => !prevState);
  }

  const validationSchema = Yup.object({
    password: Yup.string()
      .required('Password is required')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(/[0-9]/, 'Password must contain at least one number')
      .matches(
        /[@$!%*#?&]/,
        "Password must contain at least one special character"
      )
      .required('password is required'),
       confirm_password: Yup.string()
       .required('Confirm Password is required') // Required check first
       .oneOf([Yup.ref('password'), null], 'Passwords must match'),

    phone_number: Yup.string()
    .min(10, 'Phone Number must have 10 digits')
      .required('Phone number is required'),

    company_email: Yup.string()
      .required('Email is required')
      .email('Invalid email format'),

    company_name: Yup.string().required('Company name is required'),

    name: Yup.string()
      .required('Name is required')
  });

  const [errors, setErrors] = useState({});

  const handleSignup = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate(form, { abortEarly: false });
     
      setLoading(true)
      const config1 = {
        method: 'post',
        url: `${config.apiUrl}employer/signup`,
        data: form
      };

      const response = await axios(config1);

      if (response.status === 201) {
        toast.success(response.data.message);
        setShowOtpInput(true)
        setTimeLeft(30); ///timer
        console.log("Success:", response.data);

      } else {

        toast.error("Something went wrong");
      }


    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach(err => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
      } else {
        toast.error(error.response.data.detail)
        console.error("Unexpected error:", error);
      }      
    }
    finally {
      setLoading(false)
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    try {
      if(otp===""){
        setOtpErrors("OTP required")
      }
      const response = await axios.post(`${config.apiUrl}employer/verify-otp`, { otp, company_email: form.company_email });

      if (response.status === 200) {
        dispatch({
          type: Auth,
          payload: response.data
        })
        toast.success("Employer Registered");
        window.location.href = `${config.employerUrl}setting`;

      } else {
        toast.error("Failed to verify OTP");
      }

    } catch (error) {
      if(otp !== "" ){
        setOtpErrors(error.response.data.detail)
      }
      else{
       console.log(error)
      }
    }
  };

  const inputHandler =async (e) => {
    const { name, value } = e.target;
    setForm((old) => ({ ...old, [name]: value }));
    try {
      // await validationSchema.validateAt(name, { [name]: value });
      setErrors(prevErrors => ({ ...prevErrors, [name]: undefined }));
  } catch (error) {
      setErrors(prevErrors => ({ ...prevErrors, [name]: error.message }));
  }
  };
  const resendOtp = async () => {
    setCanResendOtp(false);
    setTimeLeft(30); // Reset the timer
    try {
      const response = await axios.post(`${config.apiUrl}employer/resend-otp`, { company_email: form.company_email });
      if (response.status === 200) {
        toast.success(response.data.message);
      } else {
        toast.error("Failed to resend OTP");
      }
    } catch (error) {
      toast.error("Error while resending OTP");
    }
  };
  return (
    <>
      <Toaster />
      <div className="">
        {!showOtpInput ?
          (<form className="space-y-3" onSubmit={handleSignup}>
            <div>
              <label className="block">Name<span className="text-red-500">*</span></label>
              <input
                type="text"
                id="name"
                name="name"
                value={form.name}
                onChange={inputHandler}
                className={`mt-1 block w-full p-3 border shadow-sm placeholder-gray-400 focus:outline-none sm 
                  ${errors.name ? 'border-red-500' : 'border-gray-300'}`} 
                placeholder="Enter your name"
              />
              {errors.name && <span className="text-red-500 text-xs">{errors.name}</span>}
            </div>
            <div>
              <label className="block">Company Name<span className="text-red-500">*</span></label>
              <input
                type="text"
                name="company_name"
                value={form.company_name}
                onChange={inputHandler}
                className={`mt-1 block w-full p-3 border shadow-sm placeholder-gray-400 focus:outline-none sm 
                  ${errors.company_name ? 'border-red-500' : 'border-gray-300'}`} 
                placeholder="Enter your name"
              />
              {errors.company_name && <span className="text-red-500 text-xs">{errors.company_name}</span>}
            </div>
            <div>
              <label className="block">Company Email address<span className="text-red-500">*</span></label>
              <input
                type="text"
                name="company_email"
                value={form.company_email}
                onChange={inputHandler}
                className={`mt-1 block w-full p-3 border shadow-sm placeholder-gray-400 focus:outline-none sm 
                  ${errors.company_email ? 'border-red-500' : 'border-gray-300'}`} 
                placeholder="Enter your email"
              />
              {errors.company_email && <span className="text-red-500 text-xs">{errors.company_email}</span>}
            </div>
            <div>
              <label className="block">Phone number<span className="text-red-500">*</span></label>
              <input
                type="number"
                name="phone_number"
                value={form.phone_number}
                onChange={inputHandler}
                className={`mt-1 block w-full p-3 border shadow-sm placeholder-gray-400 focus:outline-none sm 
                  ${errors.phone_number ? 'border-red-500' : 'border-gray-300'}`} 
                placeholder="Enter your phone number"
              />
              {errors.phone_number && <span className="text-red-500 text-xs">{errors.phone_number}</span>}
            </div>
            <div>
              <label className="block">Password<span className="text-red-500">*</span></label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={form.password}
                  onChange={inputHandler}
                  className={`mt-1 block w-full p-3 border shadow-sm placeholder-gray-400 focus:outline-none sm 
                  ${errors.password ? 'border-red-500' : 'border-gray-300'}`} 
                  placeholder="Enter your password"
                />
              {errors.password && <span className="text-red-500 text-xs">{errors.password}</span>}
                <span
                  onClick={togglePasswordVisibility}
                  className="absolute right-3 top-1 cursor-pointer"
                >
                  {showPassword ? <VisibilityOffIcon fontSize="14px"/> : <VisibilityIcon fontSize="14px"/>}
                </span>
              </div>
            </div>
            <div>
              <label className="block">Confirm Password<span className="text-red-500">*</span></label>
              <div className="relative">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirm_password"
                  value={form.confirm_password}
                  onChange={inputHandler}
                  className={`mt-1 block w-full p-3 border shadow-sm placeholder-gray-400 focus:outline-none sm 
                  ${errors.confirm_password ? 'border-red-500' : 'border-gray-300'}`} 
                  placeholder="Confirm password"
                />   
              {errors.confirm_password && <span className="text-red-500 text-xs">{errors.confirm_password}</span>}
                <span
                  onClick={toggleConfirmPassword}
                  className="absolute right-3 top-1 cursor-pointer"
                >
                  {showConfirmPassword ? <VisibilityOffIcon fontSize="14px"/> : <VisibilityIcon fontSize="14px"/>}
                </span>
              </div>
            </div>
            {/* <div className="flex items-center mb-4">
            <input id="default-checkbox" type="checkbox" />
            <label htmlFor="default-checkbox" className="ms-2 dark:text-gray-300">Remember me<span className="text-red-500">*</span></label>
          </div> */}
            <div>
              <button type="submit" className="theme-btn rounded-md mr-2 text-white">Sign up
                {loading == true ?
                  <CircularProgress
                    size={20}
                    disableShrink
                    style={{ color: "#fff", margin: "0 10px" }} />
                  : null}
              </button>
            </div>
          </form>)
          : (
            <form className="space-y-4 mt-4" onSubmit={handleOtpSubmit}>
              <div>
                <label className="block">Enter OTP<span className="text-red-500">*</span></label>
                <input
                  type="text"
                  name="otp"
                  value={otp}
                  onChange={(e) => {setOtp(e.target.value);
                    setOtpErrors(undefined);
                  }}
                  className={`mt-1 block w-full p-3 border shadow-sm placeholder-gray-400 focus:outline-none sm 
                  ${errors.name ? 'border-red-500' : 'border-gray-300'}`} 
                  placeholder="Enter the OTP"
                />
              {otpErrors && <span className="text-red-500 text-xs">{otpErrors}</span>}
              </div>
              <div>
                <button type="submit" className="theme-btn rounded-md mr-2 text-white">Submit OTP</button>
              </div>
              {timeLeft > 0 ? (
              <p className="text-sm"> Resend OTP in {Math.floor(timeLeft / 60)}:{String(timeLeft % 60).padStart(2, '0')}</p>
            ) : (
              <p className="text-sm">
                Didn't get the OTP?{" "}
                <span className="text-blue-500 cursor-pointer " onClick={resendOtp}>
                  Resend OTP
                </span>
              </p>
            )}
            </form>
          )}

      </div>
    </>
  );
};

export default EmployerSignup;
