import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import JobDetailsFrom from './JobDetailsFrom';
import JobDescriptionForm from './jonDescriptionForm';
import JobPreviewForm from './jobPreviewForm';
import axios from 'axios';
import config from '../../../config/config';
import { useSelector } from 'react-redux';
import { toast, Toaster } from 'react-hot-toast';
import CloseIcon from '@mui/icons-material/Close';
import AppBar from '../dashboard/Appbar';

const steps = ['Job Details', 'Job Description', 'Job Preview'];

export default function PostJobForm({ handleCloseFrom, initialData }) {
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const loginData = useSelector((state) => state.auth.Auth);

    // Define errors state for each step
    const [errors, setErrors] = React.useState({
        jobDetailsErrors: {},
        jobDescriptionErrors: {},
        jobPreviewErrors: {},
    });

    const [formData, setFormData] = React.useState({
        job_title: initialData?.title || "",
        job_type: initialData?.job_type || "",
        job_mode: initialData?.job_mode || "",
        location: initialData?.location || "",
        work_experience_min: initialData?.experience?.split('-')[0] || "",
        work_experience_max: initialData?.experience?.split('-')[1] || "",
        notice_period: initialData?.notice_period || "",
        expected_salary_min: initialData?.salary_min || "",
        expected_salary_max: initialData?.salary_max || "",
        job_description: initialData?.job_description || "",
        skill_set: initialData?.skill_set || [],
    });

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    // Validation for Job Details form
    const validateJobDetails = (formData) => {
        let errors = {};
        if (!formData.job_title) errors.job_title = "Job title is required";
        if (!formData.job_type) errors.job_type = "Job type is required";
        if (!formData.location) errors.location = "Location is required";
        if (!formData.job_mode) errors.job_mode = "Job Mode is required";
        if (!formData.notice_period) errors.notice_period = "Notice period is required";
        if (!formData.work_experience_max) errors.work_experience_max = "Work exp. is required";
        if (!formData.work_experience_min) errors.work_experience_min = "Work exp. is required";
        if (!formData.expected_salary_max) errors.expected_salary_max = "Expected salary is required";
        if (!formData.expected_salary_min) errors.expected_salary_min = "Expected salary is required";

        return errors;
    };

    // Validation for Job Description form
    const validateJobDescription = (formData) => {
        let errors = {};
        if (!formData.job_description) errors.job_description = "Job description is required";
        if (formData.skill_set.length === 0) errors.skill_set = "At least one skill is required";
        // Add other necessary validations
        return errors;
    };

    const handleNext = () => {
        let newSkipped = skipped;
        let validationErrors = {};

        // Run validation depending on the active step
        if (activeStep === 0) {
            validationErrors = validateJobDetails(formData);
            setErrors((prevErrors) => ({ ...prevErrors, jobDetailsErrors: validationErrors }));
        } else if (activeStep === 1) {
            validationErrors = validateJobDescription(formData);
            setErrors((prevErrors) => ({ ...prevErrors, jobDescriptionErrors: validationErrors }));
        }

        // If validation fails, do not proceed to the next step
        if (Object.keys(validationErrors).length > 0) {
            return;
        }

        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        setFormData({
            job_title: "",
            job_type: "",
            job_mode: "",
            location: "",
            work_experience: "",
            notice_period: "",
            expected_salary_min: "",
            expected_salary_max: "",
            job_description: "",
            skill_set: [],
        });
    };

    const handleFinish = async (e) => {
        e.preventDefault();

        try {
            if (initialData && initialData.id) {
                const response = await axios.patch(`${config.apiUrl}employer/jobs/${initialData.id}`, formData, {
                    headers: {
                        'Authorization': `Bearer ${loginData.access_token}`,
                    },
                });
                if (response.status === 200) {
                    toast.success("Job updated successfully");
                    window.location.href = `${config.employerUrl}job-listing`;
                }
            } else {
                const response = await axios.post(`${config.apiUrl}employer/jobs`, formData, {
                    headers: {
                        'Authorization': `Bearer ${loginData.access_token}`,
                    },
                });
                if (response.status === 201) {
                    toast.success(response.data.message);
                    // setTimeout(() => { window.location.reload() }, [500]);
                    window.location.href = `${config.employerUrl}job-listing`;
                }
            }
           
        } catch (error) {
            console.error(error);
            if (error.response && error.response.data) {
                toast.error("An error occurred, please check the fields.");
            }
        }
    };

    // Render each step's content
    const renderStepContent = (step) => {
        switch (step) {
            case 0:
                return <JobDetailsFrom formData={formData} setFormData={setFormData} errors={errors.jobDetailsErrors} setErrors={setErrors}/>;
            case 1:
                return <JobDescriptionForm formData={formData} setFormData={setFormData} errors={errors.jobDescriptionErrors} setErrors={setErrors}/>;
            case 2:
                return <JobPreviewForm formData={formData} setFormData={setFormData} errors={errors.jobPreviewErrors} setErrors={setErrors} />;
            default:
                return 'Unknown step';
        }
    };
    

    return (
        <>
        <AppBar/>
        <Box className="post-form">
            {/* <div className='flex justify-end'><CloseIcon onClick={handleCloseFrom} style={{ cursor: "pointer" }} /></div> */}
            <Stepper activeStep={activeStep} className='job-stepper'>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepOptional(index)) {
                        labelProps.optional = (
                            <Typography variant="caption"></Typography>
                        );
                    }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                        All steps completed - you&apos;re finished
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleReset}>Reset</Button>
                    </Box>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {renderStepContent(activeStep)}
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <button
                            className='theme-btn text-white'
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            style={{ marginRight: "10px", display: activeStep === 0 ? 'none' : 'inline-flex' }}
                        >
                            Back
                        </button>
                        <button type='button' onClick={activeStep === steps.length - 1 ? handleFinish : handleNext} className='theme-btn text-white'>
                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                        </button>
                    </Box>
                </React.Fragment>
            )}
        </Box>
        </>
    );
}
