import React, { useState, useEffect } from "react";
import { getLocation } from "../../../utils/candidate.api/candidate.api.services";

const JobDetailsForm = ({ formData, setFormData, errors,setErrors }) => {
    const [location, setLocation] = useState([]);

    const noticePeriod = [
        { name: "Immediate/15 days" },
        { name: "30 days" },
        { name: "60 days" },
        { name: "90 days or more" },

    ];

    const fetchLocation = async () => {
        try {
            let response = await getLocation();
            setLocation(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchLocation();
    }, []);

    // Handle input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors.jobDetailsErrors };
            if (value) delete updatedErrors[name]; // Remove specific error if field is filled
            return {
              ...prevErrors,
              jobDetailsErrors: updatedErrors,
            };
          });
    };

    return (
        <>
            <div className="form-theme mt-8">
                <div className="grid gap-y-3 gap-x-8 mb-6 md:grid-cols-2">
                    <div>
                        <label htmlFor="job_title" className="block mb-1">Job Title<span className="text-red-500">*</span></label>
                        <input
                            type="text"
                            name="job_title"
                            className="w-full border border-gray-300"
                            placeholder="UI/UX Designer"
                            value={formData.job_title || ''}
                            onChange={handleInputChange}
                        />
                        {errors.job_title && <p className="text-xs text-red-500">{errors.job_title}</p>}
                    </div>
                    <div>
                        <label htmlFor="job_type" className="block mb-1">Job Type<span className="text-red-500">*</span></label>
                        <select
                            id="job_type"
                            name="job_type"
                            className="w-full border border-gray-300"
                            value={formData.job_type || ''}
                            onChange={handleInputChange}
                        >
                            <option value="" disabled hidden>Job Type</option>
                            <option value="Full-time">Full-time</option>
                            <option value="Internship">Internship</option>
                            <option value="Contractual">Contractual</option>
                        </select>
                        {errors.job_type && <p className="text-xs text-red-500">{errors.job_type}</p>}

                    </div>
                    <div>
                        <label htmlFor="job_mode" className="block mb-1">Job Mode<span className="text-red-500">*</span></label>
                        <select
                            id="job_mode"
                            name="job_mode"
                            className="w-full border border-gray-300"
                            value={formData.job_mode || ''}
                            onChange={handleInputChange}
                        >
                            <option value="" disabled hidden >Job Mode</option>
                            <option value="Hybrid">Hybrid</option>
                            <option value="Remote">Remote</option>
                            <option value="On-site">On-site</option>
                        </select>
                        {errors.job_mode && <p className="text-xs text-red-500">{errors.job_mode}</p>}
                    </div>
                    <div>
                        <label htmlFor="notice_period" className="block mb-1">Notice Period<span className="text-red-500">*</span></label>
                        <select
                            id="notice_period"
                            name="notice_period"
                            className="w-full border border-gray-300"
                            value={formData.notice_period || ''}
                            onChange={handleInputChange}
                        >
                            <option value="" disabled hidden>Notice Period</option>
                            {noticePeriod.map((items) => (
                                <option key={items.name} value={items.name}>{items.name}</option>
                            ))}
                        </select>
                        {errors.notice_period && <p className="text-xs text-red-500">{errors.notice_period}</p>}
                    </div>
                    <div>
                        <label htmlFor="location" className="block mb-1">Location<span className="text-red-500">*</span></label>
                        <select
                            id="location"
                            name="location"
                            className="w-full border border-gray-300"
                            value={formData.location || ''}
                            onChange={handleInputChange}
                        >
                            <option value="" disabled hidden>Location</option>
                            {location.map((item) => (
                                <option key={item} value={item}>{item}</option>
                            ))}
                        </select>
                        {errors.location && <p className="text-xs text-red-500">{errors.location}</p>}
                    </div>
                    <div>
                        <label htmlFor="expected_salary" className="block mb-1">Work Experience<span className="text-red-500">*</span></label>
                        <div className="grid gap-x-6 gap-y-3 mb-6 md:grid-cols-2">
                            <div>
                                <input
                                    type="number"
                                    name="work_experience_min"
                                    className="w-full border border-gray-300"
                                    placeholder="Min."
                                    value={formData.work_experience_min || ''}
                                    onChange={handleInputChange}
                                />
                                {errors.work_experience_min && <p className="text-xs text-red-500">{errors.work_experience_min}</p>}
                            </div>
                            <div>
                                <input
                                    type="number"
                                    name="work_experience_max"
                                    className="w-full border border-gray-300"
                                    placeholder="Max."
                                    value={formData.work_experience_max || ''}
                                    onChange={handleInputChange}
                                />
                                {errors.work_experience_max && <p className="text-xs text-red-500">{errors.work_experience_max}</p>}
                            </div>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="expected_salary" className="block mb-1">Expected Salary / Yearly<span className="text-red-500">*</span></label>
                        <div className="grid gap-x-6 gap-y-3 mb-6 md:grid-cols-2">
                            <div>
                                <input
                                    type="number"
                                    name="expected_salary_min"
                                    className="w-full border border-gray-300"
                                    placeholder="Min."
                                    value={formData.expected_salary_min || ''}
                                    onChange={handleInputChange}
                                />
                                {errors.expected_salary_min && <p className="text-xs text-red-500">{errors.expected_salary_min}</p>}
                            </div>
                            <div>
                                <input
                                    type="number"
                                    name="expected_salary_max"
                                    className="w-full border border-gray-300"
                                    placeholder="Max."
                                    value={formData.expected_salary_max || ''}
                                    onChange={handleInputChange}
                                />
                                {errors.expected_salary_max && <p className="text-xs text-red-500">{errors.expected_salary_max}</p>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default JobDetailsForm;
