import React, { useState } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { Toaster, toast } from 'react-hot-toast';
import config from '../../../config/config';
import { setParsedCvData } from '../../../redux copy/actionTypes';
import Loader from '../../loader';

const DragDropFile = () => {
  const loginData = useSelector((state) => state.auth.Auth);
  const dispatch = useDispatch();
  const [openLoader, setOpenLoader] = useState(false);
  const [progress, setProgress] = useState(0);
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handleUpload = async () => {
    if (!file) {
      console.error('Please select a file first!');
      return;
    }

    const formData = new FormData();
    formData.append('resume_file', file);

    setOpenLoader(true);
    setProgress(0); // Initialize progress

    // Timer to increment progress until the API call completes
    const progressInterval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress < 95) {
          return prevProgress + 5; // Increase progress by 5%
        }
        return 100; // Ensure it doesn't exceed 100%
      });
    }, 500); // Update every 500 milliseconds

    try {
      const res = await axios.post(`${config.apiUrl}candidate/parse_cv`, formData, {
        headers: {
          'Authorization': `Bearer ${loginData.access_token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (res.status === 200) {
        toast.success(res.data.message);
        window.location.href = `${config.candidateUrl}setting`;
        dispatch(setParsedCvData(res.data));
      }
      console.log(res.data);

    } catch (error) {
      console.error('Error uploading the file:', error);
      toast.error('File upload failed.');
    } finally {
      clearInterval(progressInterval); // Stop the progress increment
      setProgress(100); // Set progress to 100% when done
      setTimeout(() => {
        setOpenLoader(false); // Close loader after a short delay
      }, 500); // Wait a moment to show 100%
    }
  };

  return (
    <>
      <Toaster />
      <Loader openLoader={openLoader} progress={progress} />
      <div className="flex items-center justify-center w-full">
        <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center mx-10 w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer">
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <img src="images/icons/upload.svg" className="mb-5" alt="upload icon" />
            <p className="mb-2 text-md text-black">Select a file or drag and drop here</p>
            <p className="text-xs text-gray-500">JPG, PNG or PDF, file size no more than 10MB</p>
          </div>
          <input id="dropzone-file" type="file" onChange={handleFileChange} />
        </label>
      </div>
      <div>
        <button onClick={handleUpload} className="block theme-btn text-white mt-4 float-right">Upload</button>
      </div>
    </>
  );
};

export default DragDropFile;
