
import React, { Component, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import config from '../config/config';
import Home from '../components/Home/home';
import ComingSoon from '../components/coming soon/comming2';
import PrivacyPolicy from '../components/content page/privacyPolicy';
import TermsConditions from '../components/content page/termsCondition';
import SignUp from '../components/Sign Up/SignUp';
import Login from '../components/Login/login';
import CandidateDashboard from '../components/candidate/dashboard/dashboard';
import store, { persistor } from '../redux copy/storeConfig';
import { Provider } from 'react-redux';
import { PersistGate } from "redux-persist/integration/react";

import Setting from '../components/candidate/dashboard/setting';
import EmployerDashboard from '../components/employer/dashboard/dashboard';
import JobListening from '../components/employer/jobListening/index';
import TotalApplicants from '../components/employer/total Applicants';
import AllApplicants from '../components/employer/all Applicants';
import EmployerSetting from '../components/employer/setting';
import Configuration from '../components/employer/configuration';
import Recomendation from '../components/candidate/recomendation';
import MyApplications from '../components/candidate/my application';
import SavedJob from '../components/candidate/saved jobs';

import ProtectedRoute from '../components/privateRoute';
import MySchedule from '../components/employer/configuration/mySchedule';
import Help from '../components/employer/help';
import PostJobForm from '../components/employer/Job-post/postFrom';

const RouterComponent = () => {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter >
                    {/* <Suspense fallback={<div>Loading...</div>}> */}
                    <Routes>
                        <Route path={`${config.baseUrl}`} element={<Home />} />
                        <Route path={`${config.baseUrl}coming-soon`} element={<ComingSoon />} />
                        <Route path={`${config.baseUrl}privacy-policy`} element={<PrivacyPolicy />} />
                        <Route path={`${config.baseUrl}terms-and-conditions`} element={<TermsConditions />} />
                        <Route path={`${config.baseUrl}signup`} element={<SignUp />} />
                        <Route path={`${config.baseUrl}login`} element={<Login />} />

                        {/* ================candidate======================== */}

                        <Route path={`${config.baseUrl}candidate/dashboard`} element={<ProtectedRoute><CandidateDashboard /></ProtectedRoute>} />
                        <Route path={`${config.candidateUrl}setting`} element={<ProtectedRoute><Setting /></ProtectedRoute>} />
                        <Route path={`${config.candidateUrl}recomendation`} element={<ProtectedRoute><Recomendation /></ProtectedRoute>} />
                        <Route path={`${config.candidateUrl}my-applications`} element={<ProtectedRoute><MyApplications /></ProtectedRoute>} />
                        <Route path={`${config.candidateUrl}saved-jobs`} element={<ProtectedRoute><SavedJob /></ProtectedRoute>} />


                        {/* ================Employer======================== */}
                        <Route path={`${config.employerUrl}dashboard`} element={<EmployerDashboard />} />
                        <Route path={`${config.employerUrl}job-listing`} element={<JobListening />} />
                        <Route path={`${config.employerUrl}total-applicants/:jobId`} element={<TotalApplicants />} />
                        <Route path={`${config.employerUrl}all-applicants`} element={<AllApplicants />} />
                        <Route path={`${config.employerUrl}setting`} element={<EmployerSetting />} />
                        <Route path={`${config.employerUrl}configuration`} element={<Configuration />} />
                        <Route path={`${config.employerUrl}mySchedule`} element={<MySchedule />} />
                        <Route path={`${config.employerUrl}help`} element={<Help />} />
                        <Route path={`${config.employerUrl}post-job`} element={<PostJobForm />} />

                    </Routes>
                    {/* </Suspense> */}
                </BrowserRouter>
            </PersistGate>
        </Provider>
    )
}
export default RouterComponent